import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { GoverningBody } from 'protos/model';
import React from 'react';
import { DebounceInput } from 'react-debounce-input';

import { Menu, MenuContent, MenuItem, MenuTrigger } from '@/components/Menu/Menu';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/Popover/Popover';

import { FilterState } from './Filters';
import { Location, MAXIMUM_DISTANCE, MINIMUM_DISTANCE } from './Location';

interface TourneyFiltersProps {
	filters: FilterState;
	updateFilter: <T extends FilterState, K extends keyof T>(key: K, value: T[K]) => void;
	resetFilters: () => void;
	onSubmit: (internalFilters?: FilterState) => void;
}

export const TourneyFilters = ({ filters, updateFilter, resetFilters, onSubmit }: TourneyFiltersProps) => {
	const { data: governingBodies } = useQuery(
		['governingBodies'],
		async () => {
			const response = await axios.get<{ results: GoverningBody[] }>('/api/tourneys/governingBodiesLookup');
			return response.data;
		},
		{
			refetchOnWindowFocus: false,
			initialData: {
				results: []
			}
		}
	);

	return (
		<>
			<div className="z-30 border-gray-200">
				<div className="container mx-auto flex items-center">
					<div className="custom-scroll flex w-full items-center justify-between overflow-y-auto whitespace-nowrap px-1 py-2">
						<div className="mr-2">
							<DebounceInput
								onKeyUp={(event: KeyboardEvent) => {
									if (event.key === 'Enter') {
										onSubmit();
									}
								}}
								type="text"
								className={`h-[44px] w-full min-w-[239px] rounded-md border border-gray-300 bg-white px-3 py-1.5 focus:border-gray-200 focus:outline-none focus:ring focus:ring-gray-100`}
								placeholder="Search for Tournaments..."
								minLength={2}
								debounceTimeout={750}
								value={filters.keyword}
								onChange={(e) => {
									updateFilter('keyword', e.target.value);
								}}
							/>
						</div>

						<div className="flex items-center">
							<div className="mr-2">
								<Menu width={160}>
									<MenuTrigger asChild>
										<div>
											<Button
												variant="secondary"
												size="lg"
												className="!font-normal !text-gray-500"
												suffixIcon={<FontAwesomeIcon icon={faAngleDown} />}
											>
												Featured
											</Button>
										</div>
									</MenuTrigger>
									<MenuContent>
										<MenuItem
											isActive={filters.featured}
											onClick={() => {
												updateFilter('featured', true);
											}}
										>
											Featured
										</MenuItem>
										<MenuItem
											isActive={!filters.featured}
											onClick={() => {
												updateFilter('featured', false);
											}}
										>
											All
										</MenuItem>
									</MenuContent>
								</Menu>
							</div>

							<div className="mr-2">
								<Popover placement="bottom-start">
									<PopoverTrigger asChild>
										<div>
											<Button
												variant="secondary"
												size="lg"
												className="!font-normal !text-gray-500"
												suffixIcon={<FontAwesomeIcon icon={faAngleDown} />}
											>
												Location
											</Button>
										</div>
									</PopoverTrigger>
									<PopoverContent>
										<div className="w-72 rounded bg-white p-4 shadow">
											<Location
												place={filters.place}
												onPlaceChange={(place) => {
													updateFilter('place', place);
												}}
												distance={filters.distance}
												onDistanceChange={(distance) => updateFilter('distance', [MINIMUM_DISTANCE, distance])}
											/>
										</div>
									</PopoverContent>
								</Popover>
							</div>

							<div className="mr-2">
								<Menu width={160}>
									<MenuTrigger asChild>
										<div>
											<Button
												variant="secondary"
												size="lg"
												className="!font-normal !text-gray-500"
												suffixIcon={<FontAwesomeIcon icon={faAngleDown} />}
											>
												Upcoming
											</Button>
										</div>
									</MenuTrigger>
									<MenuContent>
										<MenuItem
											isActive={filters.upcomingEvents}
											onClick={() => {
												updateFilter('upcomingEvents', true);
											}}
										>
											Upcoming
										</MenuItem>
										<MenuItem
											isActive={filters.pastEvents}
											onClick={() => {
												updateFilter('pastEvents', true);
											}}
										>
											Past
										</MenuItem>
									</MenuContent>
								</Menu>
							</div>

							<div className="mr-2">
								<Menu width={320} closeOnSelection={false}>
									<MenuTrigger asChild>
										<div>
											{' '}
											<Button
												variant="secondary"
												size="lg"
												className="!font-normal !text-gray-500"
												suffixIcon={<FontAwesomeIcon icon={faAngleDown} />}
											>
												Partners
											</Button>
										</div>
									</MenuTrigger>
									<MenuContent>
										<>
											{governingBodies.results.map((governingBody) => {
												return (
													<MenuItem
														key={governingBody.id}
														isActive={filters.partners.some((item) => item.id === governingBody.id)}
														onClick={() => {
															if (filters.partners.some((partner) => partner.id === governingBody.id)) {
																updateFilter(
																	'partners',
																	filters.partners.filter((partner) => partner.id !== governingBody.id)
																);
															} else {
																updateFilter('partners', [...filters.partners, governingBody]);
															}
														}}
													>
														{governingBody.name}
													</MenuItem>
												);
											})}
										</>
									</MenuContent>
								</Menu>
							</div>

							<div className="mr-2">
								<Menu width={160}>
									<MenuTrigger asChild>
										<div>
											<Button
												variant="secondary"
												size="lg"
												className="!font-normal !text-gray-500"
												suffixIcon={<FontAwesomeIcon icon={faAngleDown} />}
											>
												Prize Money
											</Button>
										</div>
									</MenuTrigger>
									<MenuContent>
										<MenuItem
											isActive={filters.prizeMoney}
											onClick={() => {
												updateFilter('prizeMoney', true);
											}}
										>
											Yes
										</MenuItem>
										<MenuItem
											isActive={!filters.prizeMoney}
											onClick={() => {
												updateFilter('prizeMoney', false);
											}}
										>
											All
										</MenuItem>
									</MenuContent>
								</Menu>
							</div>
						</div>

						<div className="flex items-center">
							<Button size="lg" variant="secondary" className="mr-4" onClick={resetFilters}>
								Reset
							</Button>
							<Button
								size="lg"
								className="!bg-blue-600 !shadow-[0] hover:!bg-blue-700"
								onClick={() => {
									onSubmit();
								}}
							>
								Apply
							</Button>
						</div>
					</div>
				</div>
			</div>
			<div className="container mx-auto mt-2">
				{filters.featured && (
					<span className="mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900 px-4 py-[10px] text-sm font-medium tracking-wide text-white hover:bg-gray-700">
						Featured
						<span className="ml-2 cursor-pointer  text-white " onClick={() => updateFilter('featured', false)}>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.place && (
					<span className="traking-wide mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900 px-4 py-[10px] text-sm font-medium text-white hover:bg-gray-700">
						{`${filters.place.formatted_address}`}
						<span className="ml-2 cursor-pointer text-white" onClick={() => updateFilter('place', null)}>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.distance[1] !== MAXIMUM_DISTANCE && (
					<span className="traking-wide mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900 px-4 py-[10px] text-sm font-medium text-white hover:bg-gray-700 ">
						{`Distance: ${filters.distance[1]}mi`}
						<span
							className="ml-2 cursor-pointer text-white"
							onClick={() => updateFilter('distance', [MINIMUM_DISTANCE, MAXIMUM_DISTANCE])}
						>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.upcomingEvents && (
					<span className="traking-wide mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900 px-4 py-[10px] text-sm font-medium text-white hover:bg-gray-700">
						Upcoming
						<span className="ml-2 cursor-pointer text-white" onClick={() => updateFilter('pastEvents', true)}>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.pastEvents && (
					<span className="mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900 px-4 py-[10px] text-sm font-medium tracking-wide text-white hover:bg-gray-700">
						Past
						<span className="ml-2 cursor-pointer text-white" onClick={() => updateFilter('upcomingEvents', true)}>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.prizeMoney && (
					<span className="mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900 px-4 py-[10px] text-sm font-medium tracking-wide text-white hover:bg-gray-700">
						With Prize Money
						<span className="ml-2 cursor-pointer text-white" onClick={() => updateFilter('prizeMoney', false)}>
							<FontAwesomeIcon icon={faTimes} />
						</span>
					</span>
				)}
				{filters.partners.map((partner) => {
					return (
						<span
							key={partner.id}
							className="mr-4 inline-flex items-center gap-1 rounded-full bg-gray-900  px-4 py-[10px] text-sm font-medium tracking-wide text-white hover:bg-gray-700"
						>
							{partner.shortName}
							<span
								className="ml-2 cursor-pointer text-white"
								onClick={() => {
									updateFilter(
										'partners',
										filters.partners.filter((item) => item.id !== partner.id)
									);
								}}
							>
								<FontAwesomeIcon icon={faTimes} />
							</span>
						</span>
					);
				})}
			</div>
		</>
	);
};
