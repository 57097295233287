import { RouteResponse } from '@pickleballinc/lib/server/next/route/types';
import axios from 'axios';

import { IsManagerDetails } from '@/types/tournaments.types';

export const getUserManageDetails = async (moduleId: string, moduleFor: string) => {
	const manageDetails = await axios.get<RouteResponse<IsManagerDetails>>('/api/user/isManager', {
		params: {
			moduleId,
			moduleFor
		}
	});

	return manageDetails.data.data;
};
