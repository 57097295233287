import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { debounce } from 'lodash';
import React from 'react';

// TODO: Implement debounced search, current location and open/close min char
interface AutocompleteProps {
	place: google.maps.places.PlaceResult | null;
	isFetchingLocation: boolean;
	onPlaceChange?: (place: google.maps.places.PlaceResult | null) => void;
}

export const Autocomplete = ({ place, isFetchingLocation, onPlaceChange }: AutocompleteProps) => {
	const containerRef = React.useRef<HTMLDivElement>(null);
	const [predictions, setPredictions] = React.useState<google.maps.places.AutocompletePrediction[] | null>([]);
	const [query, setQuery] = React.useState(() => {
		if (place && place.formatted_address) {
			return place.formatted_address;
		}

		return '';
	});
	const [fetching, setFetching] = React.useState(false);
	const [opened, setOpened] = React.useState(false);

	React.useEffect(() => {
		if (place) {
			setQuery(place?.formatted_address || '');
		}
	}, [place]);

	const searchPlaces = React.useCallback((query: string) => {
		const service = new window.google.maps.places.AutocompleteService();
		service.getPlacePredictions({ input: query, types: ['(cities)'] }, (results, status) => {
			if (status === window.google.maps.places.PlacesServiceStatus.OK) {
				setPredictions(results);
			} else {
				setPredictions([]);
			}
			setFetching(false);
		});
	}, []);

	const debouncedSearch = React.useRef(
		debounce(async (query: string) => {
			if (query.length > 1) {
				setOpened(true);
				setFetching(true);
				searchPlaces(query);
			}
		}, 300)
	).current;

	const handleSelection = React.useCallback(
		(placeId: string) => {
			// Create a new PlacesService instance
			const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

			// Call the getDetails method with the selected place ID
			placesService.getDetails({ placeId }, (place, status) => {
				if (status === window.google.maps.places.PlacesServiceStatus.OK) {
					// Do something with the place details
					setQuery(place?.formatted_address || '');
					setPredictions(predictions?.filter((item) => item.place_id === placeId) || []);
					onPlaceChange && onPlaceChange(place);
				}
				setOpened(false);
				setFetching(false);
				debouncedSearch.cancel();
			});
		},
		[onPlaceChange, debouncedSearch, predictions]
	);

	const handleClickOutside = (event: MouseEvent) => {
		if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
			setOpened(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	}, []);

	const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPredictions([]);
		setOpened(false);
		setQuery(e.currentTarget.value);
		if (e.currentTarget.value.length > 1) {
			debouncedSearch(e.currentTarget.value);
		} else {
			debouncedSearch.cancel();
		}
	};

	return (
		<div className="relative" ref={containerRef}>
			<div className="relative">
				<input
					type="text"
					className="h-10 w-full min-w-[200px] rounded border border-gray-300 bg-white px-3 text-sm focus:border-gray-200 focus:outline-none focus:ring focus:ring-gray-100 disabled:opacity-30"
					onChange={onInputChange}
					disabled={isFetchingLocation}
					value={query}
					tabIndex={-1}
					onFocus={() => {
						if (query.length > 1 && predictions && predictions?.length > 0) {
							setOpened(true);
						}
					}}
				/>
			</div>
			{opened && (
				<div className="absolute inset-x-0 top-full z-30 border border-gray-200 bg-white ring ring-gray-100">
					{fetching ? (
						<div className="flex items-center justify-center py-4">
							<FontAwesomeIcon icon={faLoader} spin />
							<span className="ml-2 text-xs">Loading...</span>
						</div>
					) : (
						<ul className="m-0 p-1">
							{predictions?.map((prediction) => (
								<li
									key={prediction.place_id}
									onClick={() => {
										handleSelection(prediction.place_id);
									}}
									className="cursor-pointer px-2 py-1 hover:bg-gray-200"
								>
									<span className="text-sm">{prediction.description}</span>
								</li>
							))}
						</ul>
					)}
				</div>
			)}
		</div>
	);
};
