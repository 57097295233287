import { faFilterList, faMinus, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer } from '@pickleballinc/react-ui';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { GoverningBody } from 'protos/model';
import * as React from 'react';

import { Accordion } from '@/components/Accordion/Accordion';
import { Button } from '@/components/Button/Button';

import { FilterState } from './Filters';
import { Location, MINIMUM_DISTANCE } from './Location';

interface MobileTourneyFiltersProps {
	filters: FilterState;
	updateFilter: <T extends FilterState, K extends keyof T>(key: K, value: T[K]) => void;
	resetFilters: () => void;
	onSubmit: () => void;
}

export const MobileTourneyFilters = ({ filters, updateFilter, resetFilters, onSubmit }: MobileTourneyFiltersProps) => {
	const [mobileFiltersToggled, setMobileFiltersToggled] = React.useState(false);
	const [activeKey, setActiveKey] = React.useState<string[]>([]);

	const { data: governingBodies } = useQuery(
		['governingBodies'],
		async () => {
			const response = await axios.get<{ results: GoverningBody[] }>('/api/tourneys/governingBodiesLookup');
			return response.data;
		},
		{
			refetchOnWindowFocus: false,
			initialData: {
				results: []
			}
		}
	);

	return (
		<div className={`sticky top-20  ${mobileFiltersToggled ? 'z-50' : 'z-40'}`}>
			<div>
				<button
					className={`flex w-full items-center justify-center border-y px-4 py-3 text-center uppercase tracking-wider${
						mobileFiltersToggled ? 'border-darkblue-500 bg-darkblue-500 text-white' : 'border-gray-200 bg-white text-black'
					}`}
					onClick={() => {
						setMobileFiltersToggled(!mobileFiltersToggled);
					}}
				>
					<FontAwesomeIcon icon={faFilterList} className="mr-2" />
					<span className="text-xs">Filters</span>
				</button>
			</div>
			<Drawer
				className="h-screen overflow-auto"
				title="Filter Tournaments"
				open={mobileFiltersToggled}
				onBackdropClick={() => setMobileFiltersToggled(false)}
				onClose={() => setMobileFiltersToggled(false)}
			>
				{mobileFiltersToggled && (
					<div className="flex  w-full flex-col justify-between bg-white">
						<Accordion activeEventKey={activeKey} onToggle={setActiveKey} element="div">
							<Accordion.Toggle className="block w-full" eventKey="featured" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Featured
									<FontAwesomeIcon
										icon={activeKey.some((key) => key === 'featured') ? faMinus : faPlus}
										className="ml-auto block"
									/>
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="featured">
								<div className="flex items-center border-b border-gray-200 p-4">
									<Button
										variant="gray"
										buttonVariantType={filters.featured ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('featured', true)}
									>
										Featured
									</Button>
									<Button
										variant="gray"
										buttonVariantType={!filters.featured ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('featured', false)}
									>
										All
									</Button>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="location" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Location
									<FontAwesomeIcon
										icon={activeKey.some((key) => key === 'location') ? faMinus : faPlus}
										className="ml-auto block"
									/>
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="location">
								<div className="flex items-center border-b border-gray-200 p-4">
									<Location
										place={filters.place}
										onPlaceChange={(place) => {
											updateFilter('place', place);
											if (filters.distance[1] === 600) updateFilter('distance', [MINIMUM_DISTANCE, 200]);
										}}
										distance={filters.distance}
										onDistanceChange={(distance) => updateFilter('distance', [MINIMUM_DISTANCE, distance])}
									/>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="upcomingEvents" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Upcoming
									<FontAwesomeIcon
										icon={activeKey.some((key) => key === 'upcomingEvents') ? faMinus : faPlus}
										className="ml-auto block"
									/>
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="upcomingEvents">
								<div className="flex items-center border-b border-gray-200 p-4">
									<Button
										variant="gray"
										buttonVariantType={filters.upcomingEvents ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('upcomingEvents', true)}
									>
										Upcoming
									</Button>
									<Button
										variant="gray"
										buttonVariantType={filters.pastEvents ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('pastEvents', true)}
									>
										Past
									</Button>
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="partners" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Partners
									<FontAwesomeIcon
										icon={activeKey.some((key) => key === 'partners') ? faMinus : faPlus}
										className="ml-auto block"
									/>
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="partners">
								<div className="flex flex-wrap items-center border-b border-gray-200 p-4">
									{governingBodies.results.map((governingBody) => {
										return (
											<span
												className={`
                        mb-2 mr-2 rounded-full border px-2 py-1 text-sm ${
							filters.partners.some((item) => item.id === governingBody.id)
								? 'border-blue-500 bg-blue-500 text-white'
								: 'border-gray-200 bg-white'
						}
                      `}
												key={governingBody.id}
												onClick={() => {
													if (filters.partners.some((partner) => partner.id === governingBody.id)) {
														updateFilter(
															'partners',
															filters.partners.filter((partner) => partner.id !== governingBody.id)
														);
													} else {
														updateFilter('partners', [...filters.partners, governingBody]);
													}
												}}
											>
												{governingBody.name}
											</span>
										);
									})}
								</div>
							</Accordion.Collapse>

							<Accordion.Toggle className="block w-full" eventKey="prizeMoney" element="button">
								<span className="flex items-center border-b border-gray-200 p-4 text-xs uppercase tracking-wider">
									Prize Money
									<FontAwesomeIcon
										icon={activeKey.some((key) => key === 'prizeMoney') ? faMinus : faPlus}
										className="ml-auto block"
									/>
								</span>
							</Accordion.Toggle>
							<Accordion.Collapse eventKey="prizeMoney">
								<div className="flex items-center border-b border-gray-200 p-4">
									<Button
										variant="gray"
										buttonVariantType={filters.prizeMoney ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('prizeMoney', true)}
									>
										Yes
									</Button>
									<Button
										variant="gray"
										buttonVariantType={!filters.prizeMoney ? 'contained' : 'outlined'}
										className="ml-2"
										onClick={() => updateFilter('prizeMoney', false)}
									>
										All
									</Button>
								</div>
							</Accordion.Collapse>
						</Accordion>

						<div className="flex items-center p-4">
							<Button
								variant="primary2"
								buttonVariantType="contained"
								buttonSize="sm"
								className="mr-2 flex-1 justify-center font-bold tracking-wider"
								onClick={() => {
									onSubmit();
									setMobileFiltersToggled(false);
								}}
							>
								Apply
							</Button>
							<Button
								variant="gray"
								className="ml-2 flex-1 justify-center font-bold"
								buttonSize="sm"
								onClick={() => {
									resetFilters();
									setMobileFiltersToggled(false);
								}}
							>
								Reset
							</Button>
						</div>
					</div>
				)}
			</Drawer>
		</div>
	);
};
