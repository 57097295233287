/* eslint-disable tailwindcss/enforces-negative-arbitrary-values */
import { faExternalLink, faLoader, faLongArrowRight, faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faSpinnerThird, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@pickleballinc/react-ui';
import { useInfiniteQuery } from '@tanstack/react-query';
import axios from 'axios';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment';
import { Url } from 'next/dist/shared/lib/router/router';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GoverningBody } from 'protos/model';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';

import { EventCard } from '@/components/EventCard';
import { Skeleton } from '@/components/EventCard/Skeleton';
import ImageWithFallback from '@/components/ImageWithFallback/ImageWithFallback';
import { RenderAd } from '@/components/RenderAd';
import { useAuth } from '@/contexts/auth/session';
import { checkIfValidUUID } from '@/core/utils/uuid';
import { useGetModuleManageDetails, useGetMyTournaments } from '@/modules/tourney/details/hooks';
import { FeaturedTourneysCarousel } from '@/modules/tourney/list/components/FeaturedTourneysCarousel';
import { Filters } from '@/modules/tourney/list/components/Filters';
import { MAXIMUM_DISTANCE } from '@/modules/tourney/list/components/Location';
import { PPATourneysCarousel } from '@/modules/tourney/list/components/PPATourneyCarousel';
import { TournamentSearchData } from '@/types/tournaments.types';
import getCDNURL from '@/utils/getCDNURL';
import getPBracketsCDNURL from '@/utils/getPBracketsCDNURL';
import { hasPermission } from '@/utils/hasPermission';

export const TourneysList = ({ ipAddress }: { ipAddress: string }) => {
	const { user } = useAuth();
	const { query } = useRouter();
	const { clubId, keyword, my_tournaments, manage, now_playing, now_registering, show_featured } = query;
	const [activeTourney, setActiveTourney] = React.useState<TournamentSearchData | undefined>();

	const [searchKeyword, setKeyword] = React.useState('');
	const [featured, setFeatured] = React.useState(false);
	const [distance, setDistance] = React.useState<number | undefined>(600);
	const [longitude, setLongitude] = React.useState<number | undefined>();
	const [latitude, setLatitude] = React.useState<number | undefined>();
	const [upcomingEvents, setUpcomingEvents] = React.useState(false);
	const [pastEvents, setPastEvents] = React.useState(false);
	const [prizeMoney, setPrizeMoney] = React.useState(false);
	const [nowPlaying, setNowPlaying] = React.useState(false);
	const [nowRegistering, setNowRegistering] = React.useState(false);

	const [myTournamentsActive, setMyTournamentsActive] = React.useState(false);
	const [myTournamentsRegistered, setMyTournamentsRegistered] = React.useState(true);
	const [myTournamentsManaging, setMyTournamentsManaging] = React.useState(false);
	const [myTournamentsFuture, setMyTournamentsFuture] = React.useState(true);
	const [myTournamentsPast, setMyTournamentsPast] = React.useState(false);

	const [partners, setPartners] = React.useState<GoverningBody[]>([]);

	useEffect(() => {
		setKeyword((keyword as string) || '');
		setFeatured(featured || !!show_featured);
		setNowPlaying(!!now_playing);

		if (!nowPlaying) setNowRegistering(!!now_registering);

		if (user?.session.token) {
			setMyTournamentsActive(!!my_tournaments);
			setMyTournamentsManaging(!!manage);
			setMyTournamentsRegistered(!manage);
		}
	}, []);

	const totalTourneysLoaded = useRef<number>(0);

	const { data, isLoading, isFetching, fetchNextPage, hasNextPage, refetch } = useInfiniteQuery(
		[
			'tourneys',
			searchKeyword,
			featured,
			distance,
			longitude,
			latitude,
			upcomingEvents,
			pastEvents,
			prizeMoney,
			partners,
			nowPlaying,
			nowRegistering,
			clubId
		],
		async ({ pageParam = 1 }) => {
			if (!featured) {
				const params = new URLSearchParams({
					current_page: pageParam,
					page_size: '20',
					past_events: pastEvents.toString(),
					upcoming_events: upcomingEvents.toString(),
					is_prize_money: prizeMoney.toString(),
					hide_pt: 'true'
				});

				if (nowPlaying) {
					params.set('now_playing', nowPlaying.toString());
				} else if (nowRegistering) {
					params.set('now_registering', nowRegistering.toString());
				} else {
					if (searchKeyword.length > 0) {
						params.set('search_name', encodeURIComponent(searchKeyword.trim()));
					}

					if (distance && distance !== MAXIMUM_DISTANCE) {
						params.set('distance', distance.toString());
						params.set('unit_of_distance', 'mi');
					}

					if (latitude) {
						params.set('latitude', latitude.toString());
					}

					if (longitude) {
						params.set('longitude', longitude.toString());
					}

					partners.forEach((item) => {
						params.set(item.filterSearchParam, 'true');
					});
				}

				if (clubId) {
					params.set('club_id', clubId.toString());
				}

				const urlCompose = params.toString();

				const response = await axios.get<{
					result: TournamentSearchData[];
					totalRecords: number;
				}>('/api/tourneys/getTournaments', { params: { queryString: urlCompose } });

				totalTourneysLoaded.current += response.data.result.length;

				return {
					page: pageParam,
					data: response.data
				};
			} else {
				const params = new URLSearchParams({
					current_page: pageParam,
					page_size: '20',
					status: 'live',
					sort_by: 'display_order',
					order_by: 'desc'
				});

				const urlCompose = params.toString();

				const featuredResponse = await axios.get<{ result: TournamentSearchData[]; totalRecords: number }>(
					'/api/tourneys/getFeaturedTournaments',
					{
						params: { queryString: urlCompose }
					}
				);

				totalTourneysLoaded.current += featuredResponse.data.result.length;

				return {
					page: pageParam,
					data: featuredResponse.data
				};
			}
		},
		{
			refetchOnWindowFocus: false,
			keepPreviousData: true,
			getNextPageParam: (lastPage) => {
				if (totalTourneysLoaded.current < lastPage.data.totalRecords && lastPage.data.result.length) return lastPage.page + 1;
				return undefined;
			}
		}
	);

	const getMyTournamentsPageType = (): string => {
		return myTournamentsManaging ? 'm' : myTournamentsRegistered ? 'r' : '';
	};

	const getMyTournamentsDateFilter = (): string => {
		return myTournamentsFuture ? 'future' : myTournamentsPast ? 'past' : '';
	};

	const { data: myTournaments, isLoading: myTournamentsLoading } = useGetMyTournaments(
		myTournamentsActive,
		user && user?.data?.pbUuid,
		getMyTournamentsPageType(),
		getMyTournamentsDateFilter()
	);

	const isSuperAdmin = hasPermission(['SUPER_ADMIN'], user?.session?.roles);

	const [manageTournamentURL, setManageTournamentURL] = useState<Url>('');

	useEffect(() => {
		if (isSuperAdmin) {
			const baseUrl = `${process.env.NEXT_PUBLIC_PBRACKETS_URL}/a5_a/pbt/ei.aspx`;
			const url = new URL(baseUrl);
			url.searchParams.set('eid', activeTourney?.TournamentID as string);
			url.searchParams.set('rurl', window.location.href);
			setManageTournamentURL(url);
		} else {
			const baseUrl = `${process.env.NEXT_PUBLIC_PBRACKETS_URL}/a5_u/pbt/ei.aspx`;
			const url = new URL(baseUrl);
			url.searchParams.set('eid', activeTourney?.TournamentID as string);
			url.searchParams.set('rurl', window.location.href);
			setManageTournamentURL(url);
		}
	}, [activeTourney]);

	useEffect(() => {
		setFeatured(false);
	}, [nowPlaying, nowRegistering, myTournamentsActive]);

	useEffect(() => {
		setFeatured(featured || !!show_featured);
	}, [featured]);

	const { data: manageDetails, isFetching: isManageStatusFetching } = useGetModuleManageDetails(
		activeTourney?.TournamentID as string,
		'Tourney',
		user?.data?.pbUuid
	);

	const numberOfCardsOfTheFirstPage = data?.pages[0]?.data.result.slice(3, hasNextPage ? 19 : 20).length;
	const [numberOfDifferentBannerImages, setNumberOfDifferentBannerImages] = useState<number>();

	const createANewTourneyURL = `https://contact.${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/contacts/tournament/create`;
	const contactATourneyURL = `https://contact.${process.env.NEXT_PUBLIC_REWRITE_DOMAIN_NAME}/contacts/tournament`;

	return (
		<>
			{/* <div className="mt-4 flex flex-nowrap justify-center overflow-hidden whitespace-nowrap">
        <div
          className="adplugg-tag"
          data-adplugg-zone="pbrackets_site_header_top_center_tourney"
        />
        <div
          className="adplugg-tag"
          data-adplugg-zone="pbrackets_site_header_top_right_200x100_tourny"
        />
      </div> */}
			{/* <div className="border-b border-t border-gray-200 bg-white py-4 lg:mt-4"> */}
			{/* // TODO: Put something to identify the section */}
			{/* <div className="container mx-auto cursor-grab">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlay
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024,
                },
                items: 8,
                partialVisibilityGutter: 40,
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464,
                },
                items: 3,
                partialVisibilityGutter: 30,
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0,
                },
                items: 3,
                partialVisibilityGutter: 30,
              },
            }}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {tourneyPartners.map((partner) => {
              return (
                <div className="flex items-center justify-center" key={partner}>
                  <div className="relative h-20 w-28">
                    <ImageWithFallback
                      src={partner}
                      fill
                      alt=""
                      className="object-contain"
                      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                    />
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div> */}
			<RenderAd
				zoneId="679340"
				pageId={100003}
				place={0}
				ipAddress={ipAddress}
				containerClassname="mx-0.5 mt-4 sm:container sm:mx-auto"
				numberOfDifferentZoneImages={numberOfDifferentBannerImages}
				setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
			/>
			<div className="my-4 border-y">
				<div className="container mx-auto grid grid-cols-2 items-center gap-4 py-4 md:flex md:grid-cols-1 md:flex-wrap">
					{!myTournamentsActive && (
						<>
							<Button
								onClick={() => {
									setNowRegistering(false);
									setNowPlaying(!nowPlaying);
								}}
								prefixIcon={nowPlaying ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={nowPlaying ? 'blue' : 'secondary'}
								size="xl"
								className="justify-center border border-gray-300 !px-2 !shadow-[0] md:!px-[inherit]"
							>
								Now Playing
							</Button>
							<Button
								onClick={() => {
									setNowPlaying(false);
									setNowRegistering(!nowRegistering);
								}}
								prefixIcon={nowRegistering ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={nowRegistering ? 'blue' : 'secondary'}
								size="xl"
								className="justify-center border border-gray-300 !px-2 !shadow-[0] md:!px-[inherit]"
							>
								Now Registering
							</Button>
						</>
					)}

					{myTournamentsActive && (
						<>
							<Button
								onClick={() => {
									setMyTournamentsManaging(!myTournamentsManaging);
									setMyTournamentsRegistered(!myTournamentsRegistered);
								}}
								prefixIcon={myTournamentsRegistered ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={myTournamentsRegistered ? 'blue' : 'secondary'}
								size="xl"
								className="justify-center border border-gray-300 !shadow-[0]"
							>
								Registered For
							</Button>
							<Button
								onClick={() => {
									setMyTournamentsRegistered(!myTournamentsRegistered);
									setMyTournamentsManaging(!myTournamentsManaging);
								}}
								prefixIcon={myTournamentsManaging ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={myTournamentsManaging ? 'blue' : 'secondary'}
								size="xl"
								className="justify-center border border-gray-300 !shadow-[0]"
							>
								Managing
							</Button>
							<Button
								onClick={() => {
									setMyTournamentsPast(!myTournamentsPast);
									setMyTournamentsFuture(!myTournamentsFuture);
								}}
								prefixIcon={myTournamentsFuture ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={myTournamentsFuture ? 'blue' : 'secondary'}
								size="xl"
								className="justify-center border border-gray-300 !shadow-[0]"
							>
								Future
							</Button>
							<Button
								onClick={() => {
									setMyTournamentsFuture(!myTournamentsFuture);
									setMyTournamentsPast(!myTournamentsPast);
								}}
								prefixIcon={myTournamentsPast ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={myTournamentsPast ? 'blue' : 'secondary'}
								size="xl"
								className="justify-center border border-gray-300 !shadow-[0]"
							>
								Past
							</Button>
						</>
					)}

					{user?.session && user?.session?.uuid ? (
						<>
							<Button
								onClick={() => {
									setMyTournamentsActive(!myTournamentsActive);
								}}
								prefixIcon={myTournamentsActive ? <FontAwesomeIcon icon={faCheck} /> : null}
								variant={myTournamentsActive ? 'blue' : 'secondary'}
								size="xl"
								className="col-span-2 justify-center font-bold md:ml-auto"
							>
								My Tournaments
							</Button>
							<Link className="contents md:ml-auto" href={createANewTourneyURL}>
								<Button size="xl" className="col-span-2 justify-center !bg-blue-600 font-bold !shadow-[0] hover:!bg-blue-700">
									Create a Tournament
								</Button>
							</Link>
						</>
					) : (
						<Link className="contents md:ml-auto" href={createANewTourneyURL}>
							<Button size="xl" className="col-span-2 justify-center !bg-blue-600 font-bold !shadow-[0] hover:!bg-blue-700 md:ml-auto">
								Create a Tournament
							</Button>
						</Link>
					)}
				</div>
			</div>
			{!myTournamentsActive && !(nowPlaying || nowRegistering) ? (
				<Filters
					initialKeyword={searchKeyword}
					initialFeatured={featured}
					onSubmit={(filters) => {
						setKeyword(filters.keyword);
						setFeatured(filters.featured);
						setUpcomingEvents(filters.upcomingEvents);
						setPastEvents(filters.pastEvents);
						setPartners(filters.partners);
						setPrizeMoney(filters.prizeMoney);
						setDistance(filters.distance[1]);

						const lat = filters.place?.geometry?.location?.lat();
						const lng = filters.place?.geometry?.location?.lng();

						setLatitude(lat);
						setLongitude(lng);

						totalTourneysLoaded.current = 0;
						refetch();
					}}
				/>
			) : null}

			{isLoading || (myTournamentsLoading && myTournamentsActive) ? (
				<div className="container mx-auto mb-10 grid grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-4">
					{Array.from(Array(8).keys()).map((index) => {
						return <Skeleton key={index} />;
					})}
				</div>
			) : (
				<>
					<div className="container mx-auto flex flex-row items-baseline py-4">
						<div className="text-2xl font-bold">Tournaments</div>
						<div className="ml-4 font-bold text-gray-500">
							Total: {!myTournamentsActive ? data?.pages[0]?.data.totalRecords : myTournaments?.length}
						</div>
					</div>
					<div className="container mx-auto grid grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-4">
						<div className="flex w-full items-center justify-center">
							<RenderAd zoneId="688399" pageId={100003} place={0} ipAddress={ipAddress} containerClassname="w-full" />
						</div>
						{myTournamentsActive && (
							<>
								{myTournaments?.map((tourney) => {
									return (
										<TourneyCard
											key={`My${tourney.TournamentID}-${tourney.Title}`}
											tourney={tourney}
											onTourneyClick={(tourney) => setActiveTourney(tourney)}
										/>
									);
								})}
							</>
						)}
						{!myTournamentsActive &&
							data?.pages[0]?.data.result.slice(0, 3).map((tourney, index) => {
								if (index < 3) {
									return (
										<TourneyCard
											key={`${tourney.TournamentID}-${tourney.Title}`}
											tourney={tourney}
											onTourneyClick={(tourney) => setActiveTourney(tourney)}
										/>
									);
								}
							})}
					</div>
					{!myTournamentsActive && !(nowPlaying || nowRegistering) ? (
						<>
							<PPATourneysCarousel onEventClick={(tourney) => setActiveTourney(tourney)} />

							<FeaturedTourneysCarousel onEventClick={(tourney) => setActiveTourney(tourney)} />
						</>
					) : null}

					{!myTournamentsActive && (
						<div className="container mx-auto grid grid-cols-2 gap-4 py-4 md:gap-10 lg:grid-cols-4 xl:grid-cols-4">
							{/* First page: Show tournaments from index 3 to 19. */}
							{data?.pages[0]?.data.result.slice(3, hasNextPage ? 19 : 20).map((tourney, index) => {
								if (numberOfCardsOfTheFirstPage !== undefined && index < numberOfCardsOfTheFirstPage - 1) {
									return (
										<TourneyCard
											key={`${tourney.TournamentID}`}
											tourney={tourney}
											onTourneyClick={(tourney) => setActiveTourney(tourney)}
										/>
									);
								}
								return (
									<React.Fragment key={`${tourney.TournamentID}`}>
										<TourneyCard tourney={tourney} onTourneyClick={(tourney) => setActiveTourney(tourney)} />
										<RenderAd
											zoneId="679340"
											pageId={100003}
											place={1}
											ipAddress={ipAddress}
											containerClassname="col-span-full grid w-full"
											numberOfDifferentZoneImages={numberOfDifferentBannerImages}
											setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
										/>
									</React.Fragment>
								);
							})}

							{/* Show the last tournament from the previous page and the first 19 tournaments from the current page */}
							{data?.pages.slice(1).map((page, pageIndex) => {
								// Get the last tournament from the previous page
								const lastTourneyOfPrevPage = data.pages[pageIndex]?.data.result.slice(-1)[0];
								const tournamentsToShow = [lastTourneyOfPrevPage];

								// Add the first 19 tournaments from the current page to the array
								// If !hasNextPage -> show all remaining data from the current page
								tournamentsToShow.push(...page.data.result.slice(0, hasNextPage ? 19 : 20));

								return tournamentsToShow.map((tourney, index) => {
									const RenderTourneyCard = () => (
										<TourneyCard tourney={tourney!} onTourneyClick={(tourney) => setActiveTourney(tourney)} />
									);

									if (index < tournamentsToShow.length - 1) {
										return <RenderTourneyCard key={`${tourney?.TournamentID}`} />;
									}

									return (
										<React.Fragment key={`${tourney?.TournamentID}`}>
											<RenderTourneyCard />
											<RenderAd
												zoneId="679340"
												pageId={100003}
												place={pageIndex + 2}
												ipAddress={ipAddress}
												containerClassname="col-span-full grid w-full"
												numberOfDifferentZoneImages={numberOfDifferentBannerImages}
												setNumberOfDifferentZoneImages={setNumberOfDifferentBannerImages}
											/>
										</React.Fragment>
									);
								});
							})}
						</div>
					)}
				</>
			)}
			{!myTournamentsActive && hasNextPage ? (
				<div className="my-12 flex items-center justify-center">
					<Button
						variant="secondary"
						size="md"
						onClick={() => {
							fetchNextPage();
						}}
						disabled={isFetching}
						className={`px-8 ${isFetching ? 'bg-gray-100 text-gray-400 hover:bg-gray-200' : ''}`}
					>
						{isFetching ? 'Loading more...' : 'Load more'}
						{isFetching ? (
							<FontAwesomeIcon icon={faLoader} spin className="ml-2" />
						) : (
							<FontAwesomeIcon icon={faLongArrowRight} className="ml-2" />
						)}
					</Button>
				</div>
			) : null}

			<AnimatePresence>
				{activeTourney && (
					<div className="fixed inset-0 z-50 flex items-center justify-center p-4">
						<motion.div className="fixed inset-0 bg-black bg-black/75" onClick={() => setActiveTourney(undefined)} />
						<motion.div
							layoutId={`tourney-card-${activeTourney.TournamentID}`}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							transition={{ duration: 0.2 }}
							className="relative flex w-full max-w-2xl flex-col"
						>
							<div> {renderTourneyCardLabels(activeTourney, true)}</div>
							<div className="flex w-full flex-col md:flex-row">
								<div
									className="absolute -right-4 -top-4 z-50 flex size-8 cursor-pointer items-center justify-center rounded-full bg-white"
									onClick={() => {
										setActiveTourney(undefined);
									}}
								>
									<FontAwesomeIcon icon={faTimes} className="" />
								</div>
								<div className="relative z-10 flex-1 border-b border-gray-200 bg-white md:rounded-bl-md md:border-r">
									<div className="relative">
										<EventCard.Image
											imageSrc={
												activeTourney.Logo && activeTourney.Logo.includes('/uploads/Clubs/')
													? getPBracketsCDNURL(activeTourney.Logo, 383, 240)
													: getCDNURL(
															'${process.env.NEXT_PUBLIC_IMAGE_CDN}/placeholder/pickleball_transparent_square.png',
															383,
															240
														)
											}
											className="h-44 md:h-60"
										/>

										{activeTourney.IsPrizeMoney && (
											<div className="absolute -bottom-4 right-2 flex size-8 items-center justify-center rounded-full bg-success-500">
												<FontAwesomeIcon icon={faMoneyBill} className="text-white" />
											</div>
										)}
									</div>
									<EventCard.Content>
										<p className="mb-1 text-sm uppercase tracking-wide text-gray-600">
											{`${moment.parseZone(activeTourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
												.parseZone(activeTourney.TourneyToDate)
												.format('MMM DD, YYYY')}`}
										</p>
										<p className={`line-clamp-2 text-xl font-bold uppercase leading-6 text-gray-900`}>{activeTourney.Title}</p>
										<p className="mt-auto flex items-center pt-2 font-body text-base text-gray-600">
											{`${activeTourney.LocationCity}, ${activeTourney.LocationState}`}
											{activeTourney.LocationCountry && `, ${activeTourney.LocationCountry}`}
										</p>
									</EventCard.Content>
									<EventCard.Footer>
										<EventCard.PlayerCount count={activeTourney.RegistrationCount} />
									</EventCard.Footer>
									{activeTourney.GoverningBodiesData && activeTourney.GoverningBodiesData.length > 0 && (
										<div className="flex w-full flex-row justify-center py-2">
											{activeTourney.GoverningBodiesData.map((gBody) => {
												return (
													<>
														{gBody.Level_Logo !== '' && (
															<Image
																width={100}
																height={100}
																alt={gBody.ClubTitle.toLowerCase() + '_logo'}
																key={gBody.ClubID}
																src={getPBracketsCDNURL(gBody.Level_Logo, 100, 55)}
															/>
														)}
													</>
												);
											})}
										</div>
									)}
								</div>
								<div className="relative flex w-full flex-col justify-center rounded-b-md bg-white p-2 md:w-72 md:rounded-bl-none md:rounded-br-md">
									{isManageStatusFetching ? (
										<div className="flex items-center justify-center py-6 text-sm text-gray-600">
											<div className="block">
												<FontAwesomeIcon icon={faSpinnerThird} spin />
											</div>
											<span className="ml-2">Please wait...</span>
										</div>
									) : (
										<div className="my-4">
											<div className="px-4 text-[12px] font-medium uppercase tracking-wider text-gray-400">Menu:</div>
											<ul className="mt-2 p-0">
												<li className="mb-2 list-none">
													<Link
														href={
															checkIfValidUUID(activeTourney.TournamentID)
																? `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${activeTourney.slug || activeTourney.TournamentID}`
																: `https://www.pickleballtournaments.com/tournamentinfo.pl?tid=${activeTourney.TournamentID}`
														}
														className={classNames(
															'block w-full rounded-md  px-4 py-1.5 text-sm transition duration-150 ',
															{
																'bg-blue-500 text-white hover:bg-blue-700': !activeTourney.IsRegClosed,
																'decoration-black underline-offset-2 hover:underline': activeTourney.IsRegClosed
															}
														)}
													>
														{activeTourney.IsRegClosed ? 'Details' : 'Details & Registration'}

														{!checkIfValidUUID(activeTourney.TournamentID) && (
															<FontAwesomeIcon icon={faExternalLink} className="ml-2 text-sm" />
														)}
													</Link>
												</li>
												<li className="mb-2 list-none">
													<Link
														href={
															checkIfValidUUID(activeTourney.TournamentID)
																? `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${activeTourney.slug || activeTourney.TournamentID}/events`
																: `${process.env.NEXT_PUBLIC_PT_URL}/tournaments`
														}
														className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
													>
														Events
													</Link>
												</li>
												<li className="mb-2 list-none">
													<Link
														href={
															checkIfValidUUID(activeTourney.TournamentID)
																? `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${activeTourney.slug || activeTourney.TournamentID}/find-player`
																: `${process.env.NEXT_PUBLIC_PT_URL}/tournaments`
														}
														className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
														rel="nofollow noindex"
													>
														Find a Player
													</Link>
												</li>
												<li className="mb-2 list-none">
													<Link
														href={
															checkIfValidUUID(activeTourney.TournamentID)
																? `${process.env.NEXT_PUBLIC_PT_URL}/tournaments/${activeTourney.slug || activeTourney.TournamentID}/events`
																: `${process.env.NEXT_PUBLIC_PT_URL}/tournaments`
														}
														className={classNames(
															'block w-full rounded-md  px-4 py-1.5 text-sm transition duration-150 ',
															{
																'bg-blue-500 text-white hover:bg-blue-700': activeTourney.IsRegClosed,
																'decoration-black underline-offset-2 hover:underline': !activeTourney.IsRegClosed
															}
														)}
													>
														Draws & Results
													</Link>
												</li>
												{/* <li className="mb-2 list-none">
                        <Link
                          href={`${process.env.NEXT_PUBLIC_PT_URL}/tournaments`}
                          className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
                        >
                          Stats
                        </Link>
                      </li> */}
												<li className="mb-2 list-none">
													<Link
														href={`${contactATourneyURL}/${activeTourney.TournamentID}`}
														className="px-4 text-sm decoration-black underline-offset-2 hover:underline"
													>
														Contact Tourney
													</Link>
												</li>
												{user && user.session && (isSuperAdmin || manageDetails?.IsManager) && (
													<li className="mb-2 list-none">
														<Link
															href={manageTournamentURL}
															className="block w-full rounded-md bg-darkblue-500 px-4 py-1.5 text-sm text-white transition duration-150 hover:bg-darkblue-700"
														>
															Manage Tourney
														</Link>
													</li>
												)}
											</ul>
										</div>
									)}

									{activeTourney.titleSponsorLogo && (
										<div className="relative mx-4 flex h-20 items-center justify-center rounded-lg">
											<Link target="_blank" href={activeTourney.titleSponsorUrl}>
												<ImageWithFallback
													fill
													className="object-contain"
													src={activeTourney.titleSponsorLogo}
													alt={activeTourney.titleSponsorTitle + 'logo image'}
												/>
											</Link>
										</div>
									)}
								</div>
							</div>
						</motion.div>
					</div>
				)}
			</AnimatePresence>
		</>
	);
};

interface TourneyCardProps {
	tourney: TournamentSearchData;
	onTourneyClick: (tourney: TournamentSearchData) => void;
	showWaitingList?: boolean;
}

const getTourneyLabelColorClass = (tourney: TournamentSearchData) => {
	if (tourney.CancelTourney) return 'bg-error-500';

	if (
		moment(tourney.TourneyToDate)
			.utcOffset(tourney.TimeZoneUTCOffset / 60)
			.utc()
			.isBefore(moment().utc())
	)
		return 'bg-darkblue-500';

	// if (moment(tourney.TourneyToDate).isBefore(moment())) return "bg-darkblue-500";

	if (tourney.IsRegClosed) return 'bg-success-700';

	if (tourney.CostRegistrationCurrent > 0) return 'bg-success-500';

	return 'bg-secondary-500';
};

export function renderTourneyCardLabels(tourney: TournamentSearchData, roundedTop: boolean = true, roundedBottom: boolean = false) {
	const htmlToRender = [];
	if (tourney.CancelTourney) {
		htmlToRender.push(
			<div
				className={`tracking-widertext-white px-2 py-1 text-center text-xs font-semibold text-white ${roundedTop && 'rounded-t-md'} ${
					roundedBottom && 'rounded-b-md'
				} ${getTourneyLabelColorClass(tourney)}`}
			>
				Cancelled
			</div>
		);
	} else {
		if (
			moment(tourney.TourneyToDate)
				.utcOffset(tourney.TimeZoneUTCOffset / 60)
				.utc()
				.isBefore(moment().utc())
		) {
			htmlToRender.push(
				<div
					className={`tracking-widertext-white ${getTourneyLabelColorClass(tourney)} px-2 py-1 text-center text-xs font-semibold text-white ${
						roundedTop && 'rounded-t-md'
					} ${roundedBottom && 'rounded-b-md'}`}
				>
					Completed
				</div>
			);
		} else {
			if (tourney.IsRegClosed) {
				htmlToRender.push(
					<div
						className={`tracking-widertext-white ${getTourneyLabelColorClass(tourney)} px-2 py-1 text-center text-xs font-semibold text-white ${
							roundedTop && 'rounded-t-md'
						} ${roundedBottom && 'rounded-b-md'}`}
					>
						Reg. Closed
					</div>
				);
			} else {
				if (tourney.CostRegistrationCurrent > 0) {
					htmlToRender.push(
						<div
							className={`tracking-widertext-white ${getTourneyLabelColorClass(tourney)} px-2 py-1 text-center text-xs font-semibold text-white ${
								roundedTop && 'rounded-t-md'
							} ${roundedBottom && 'rounded-b-md'}`}
						>
							{`${tourney.PaymentCurrencyAbbreviation.toUpperCase() || 'USD'} $${tourney.CostRegistrationCurrent.toFixed(2)}`}
						</div>
					);
				} else {
					if (tourney.CostFeeOnEvents) {
						htmlToRender.push(
							<div
								className={`tracking-widertext-darkblue-500 text-white ${getTourneyLabelColorClass(
									tourney
								)} px-2 py-1 text-center text-xs font-semibold ${roundedTop && 'rounded-t-md'} ${roundedBottom && 'rounded-b-md'}`}
							>
								Cost Per Event
							</div>
						);
					} else {
						htmlToRender.push(
							<div
								className={`tracking-widertext-darkblue-500 text-white ${getTourneyLabelColorClass(
									tourney
								)} px-2 py-1 text-center text-xs font-semibold ${roundedTop && 'rounded-t-md'} ${roundedBottom && 'rounded-b-md'}`}
							>
								Free
							</div>
						);
					}
				}
			}
		}
	}
	return htmlToRender;
}

export const TourneyCard = ({ tourney, onTourneyClick, showWaitingList = false }: TourneyCardProps) => {
	const renderLabels = React.useMemo(() => renderTourneyCardLabels(tourney), [tourney]);

	const isTourneyPPA = (): boolean => {
		return tourney.ClubID === '80f17003-cd92-4fc4-84e4-06d38e096042';
	};

	const getCludIDBg = (): string => {
		return isTourneyPPA() ? 'bg-blue-900' : 'bg-white';
	};

	return (
		<div key={tourney.TournamentID} className="relative flex w-full max-w-lg">
			<EventCard onClick={() => onTourneyClick(tourney)}>
				<div className="relative">
					{tourney.IsFeatured && (
						<div className="">
							<FontAwesomeIcon
								icon={faStar}
								className="absolute left-1 top-1/2 -mt-[7px] text-sm text-white"
								data-tooltip-id="tooltip"
								data-tooltip-content="Featured tournament."
							/>
						</div>
					)}
					{renderLabels.map((item, index) => (
						<React.Fragment key={index}>{item}</React.Fragment>
					))}
				</div>
				<div className="relative">
					<EventCard.Image
						imageSrc={
							tourney.Logo && tourney.Logo.includes('/uploads/Clubs/')
								? getPBracketsCDNURL(tourney.Logo, 219, 176)
								: getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/placeholder/pickleball_transparent_square.png`, 219, 176)
						}
						alt={`${tourney.Title} Logo`}
					/>
					{tourney.IsPrizeMoney && (
						<div
							className={classNames(
								'absolute -bottom-4 right-2 flex h-8 w-8 items-center justify-center rounded-full',
								getTourneyLabelColorClass(tourney)
							)}
						>
							<FontAwesomeIcon icon={faMoneyBill} className="text-white" />
						</div>
					)}
				</div>{' '}
				<div className={classNames('flex h-full flex-col rounded-b-md', getCludIDBg())}>
					<EventCard.Content>
						<p className={classNames('mb-1 text-sm', isTourneyPPA() ? 'text-blue-200' : 'text-gray-600')}>
							{`${moment.parseZone(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment
								.parseZone(tourney.TourneyToDate)
								.format('MMM DD, YYYY')}`}
						</p>
						<p className={classNames('line-clamp-3 font-bold uppercase leading-6', isTourneyPPA() ? 'text-white' : 'text-gray-900')}>
							{tourney.Title}
						</p>
						<p className={classNames('mt-auto flex items-center pt-2 text-sm', isTourneyPPA() ? 'text-white' : 'text-gray-600')}>
							{`${tourney.LocationCity}, ${tourney.LocationState}`}
							{tourney.LocationCountry && `, ${tourney.LocationCountry}`}
						</p>
					</EventCard.Content>
					{tourney.GoverningBodiesData && tourney.GoverningBodiesData.length > 0 && (
						<div className="flex w-full flex-row justify-center py-2">
							{tourney.GoverningBodiesData.map((gBody) => {
								return (
									<>
										{gBody.Level_Logo !== '' && (
											<Image
												width={100}
												height={100}
												alt={gBody.ClubTitle.toLowerCase() + '_logo'}
												key={gBody.ClubID}
												src={getPBracketsCDNURL(gBody.Level_Logo, 100, 55)}
											/>
										)}
									</>
								);
							})}
						</div>
					)}
					{!tourney.hideRegisteredPlayers && (
						<EventCard.Footer>
							<div className={classNames(isTourneyPPA() ? 'text-white' : '')}>
								<EventCard.PlayerCount
									count={tourney.RegistrationCount}
									waitingListCount={showWaitingList ? tourney.waitingListCount : undefined}
								/>
							</div>
						</EventCard.Footer>
					)}
				</div>
			</EventCard>
		</div>
	);
};
