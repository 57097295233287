import { RouteResponse } from '@pickleballinc/lib/server/next/route/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { StartTimesRes } from '@/pages/api/tourneys/startTimes';
import {
	FormattedEvent,
	GameOnCourtInfo,
	GoverningBodyInfo,
	LodgingInfo,
	SponsorData,
	TeamInQueueInfo,
	TeamsInEventData,
	TournamentSearchData,
	TourneyCostData,
	TourneyHeaderData,
	TourneyOverviewDetails,
	TourneySidebarDetails
} from '@/types/tournaments.types';
import { getUserManageDetails } from '@/utils/getUserManageDetails';

import { Player } from './types';

export const getTourneySidebarDetails = async (tourneyId: string) => {
	const tourneySidebar = await axios.get<RouteResponse<TourneySidebarDetails>>('/api/tourneys/sidebarDetails', {
		params: {
			tourneyId
		}
	});

	return tourneySidebar.data.data;
};

export const getTourneyOverviewDetails = async (tourneyId: string) => {
	const tourneyOverview = await axios.get<RouteResponse<TourneyOverviewDetails>>('/api/tourneys/overviewDetails', {
		params: {
			tourneyId
		}
	});

	return tourneyOverview.data.data;
};

export const getTourneyHeader = async (tourneyId: string) => {
	const tourneyHeader = await axios.get<RouteResponse<TourneyHeaderData>>('/api/tourneys/header', {
		params: {
			tourneyId
		}
	});

	return tourneyHeader.data.data;
};

export const getTourneyEvents = async (tourneyId: string, activityId: string = '', playerName: string = '') => {
	const tourneyEvents = await axios.get<RouteResponse<FormattedEvent[]>>('/api/tourneys/getFormattedEvents', {
		params: { tourneyId, activityId, playerName }
	});

	return tourneyEvents.data.data;
};

export const getTeamsInEvent = async (eventId: string, activitySplitId?: string, calculateAgePerDate: string = '') => {
	const teamsInEvent = await axios.get<RouteResponse<TeamsInEventData[]>>('/api/tourneys/teamsInEvent', {
		params: {
			eventId,
			activitySplitId,
			calculateAgePerDate
		}
	});

	return teamsInEvent.data.data;
};

export const getPlayersWithEvents = async (tourneyId: string, searchWord: string, calculateAgePerDate: string) => {
	const tourneyPlayers = await axios.get<RouteResponse<Player[]>>('/api/tourneys/playersInTourney', {
		params: {
			tourneyId,
			searchWord,
			calculateAgePerDate
		}
	});

	return tourneyPlayers.data.data;
};

export const getStartTimes = async (tourneyId: string) => {
	const startTimes = await axios.get<RouteResponse<StartTimesRes>>('/api/tourneys/startTimes', {
		params: {
			tourneyId
		}
	});

	return startTimes.data.data;
};

export const getSponsors = async (moduleId: string, moduleFor: string) => {
	const sponsors = await axios.get<RouteResponse<SponsorData[]>>('/api/sponsors/getSponsors', {
		params: {
			moduleId,
			moduleFor
		}
	});

	return sponsors.data.data;
};

export const getLodging = async (moduleId: string) => {
	const lodging = await axios.get<RouteResponse<LodgingInfo[]>>('/api/tourneys/lodging', {
		params: {
			tourneyId: moduleId
		}
	});

	return lodging.data.data;
};

export const getUserDefinedFields = async (moduleId: string) => {
	const fields = await axios.post<{ payload: any[] }>(
		'/api/proxy/v1/pb_data/json',
		JSON.stringify({
			ModuleID: moduleId
		}),
		{
			params: {
				sp_name: 'API_v2_GetUserDefinedFields'
			}
		}
	);

	return fields.data.payload;
};

export const getTourneyCosts = async (tourneyId: string) => {
	const costs = await axios.get<RouteResponse<TourneyCostData[]>>('/api/tourneys/getTourneyCosts', {
		params: {
			tourneyId
		}
	});

	return costs.data.data;
};

export const getActivityPrintNextAvailableCountNotPlayed = async (tourneyId: string) => {
	const activities = await axios.get<RouteResponse<{ TotalActivitiesWithPrintNextAvailableNotPlayed: number }>>(
		'/api/tourneys/activityPrintNextAvailableCountNotPlayed',
		{
			params: {
				tourneyId
			}
		}
	);

	return activities.data.data;
};

export const getMyTournaments = async (pageType?: string, dateFilter?: string, searchWord?: string) => {
	const myTournaments = await axios.get<RouteResponse<TournamentSearchData[]>>('/api/tourneys/myTournaments', {
		params: {
			pageType,
			dateFilter,
			searchWord
		}
	});

	return myTournaments.data.data;
};

export const getTourneyIdFromSlug = async (slug: string) => {
	const tourneyId = await axios.get<RouteResponse<string>>('/api/tourneys/getIdFromSlug', {
		params: {
			slug
		}
	});

	return tourneyId.data.data;
};

export const getTeamsInQueue = async (tourneyId: string) => {
	const gamesOnCourt = await axios.get<RouteResponse<TeamInQueueInfo[]>>('/api/tourneys/getTeamsInQueue', {
		params: {
			tourneyId
		}
	});

	return gamesOnCourt.data.data;
};

export const getGamesOnCourt = async (tourneyId: string) => {
	const teamsInQueue = await axios.get<RouteResponse<GameOnCourtInfo[]>>('/api/tourneys/getGamesOnCourt', {
		params: {
			tourneyId
		}
	});

	return teamsInQueue.data.data;
};

export const getGoverningBodies = async (tourneyId: string) => {
	const governingBodies = await axios.get<RouteResponse<GoverningBodyInfo[]>>('/api/tourneys/getGoverningBodies', {
		params: {
			tourneyId
		}
	});

	return governingBodies.data.data;
};

export const useGetTourneyIdFromSlug = (slug: string) => {
	return useQuery(
		['tourneyIdFromSlug', slug],

		async () => getTourneyIdFromSlug(slug),
		{
			enabled: !!slug,
			refetchOnMount: false
		}
	);
};

export const useGetStartTimes = (tourneyId: string, userId?: string) => {
	return useQuery(
		['tourneyStartTimes', tourneyId, userId],

		async () => getStartTimes(tourneyId),
		{
			enabled: !!tourneyId
		}
	);
};

export const useGetMyTournaments = (myTournamentsActive: boolean, userId?: string, pageType?: string, dateFilter?: string, searchWord?: string) => {
	return useQuery(['myTournaments', userId, pageType, dateFilter, searchWord], async () => getMyTournaments(pageType, dateFilter, searchWord), {
		enabled: myTournamentsActive && !!userId
	});
};

export const useGetSponsors = (moduleId: string, moduleFor: string) => {
	return useQuery(['sponsors', moduleId, moduleFor], async () => getSponsors(moduleId, moduleFor), {
		enabled: !!moduleId && moduleId !== ''
	});
};

export const useGetLodging = (moduleId: string) => {
	return useQuery(['lodging', moduleId], async () => getLodging(moduleId), {
		refetchOnMount: false,
		enabled: !!moduleId
	});
};

export const useGetUserDefinedFields = (moduleId: string) => {
	return useQuery(['userDefinedFields', moduleId], async () => getUserDefinedFields(moduleId));
};

export const useGetTourneyCosts = (tourneyId: string) => {
	return useQuery(['tourneyCosts', tourneyId], async () => getTourneyCosts(tourneyId), {
		enabled: !!tourneyId,
		refetchOnMount: false
	});
};

export const useGetActivityPrintNextAvailableCountNotPlayed = (tourneyId: string) => {
	return useQuery(['activityPrintNextAvailableCountNotPlayed', tourneyId], async () => getActivityPrintNextAvailableCountNotPlayed(tourneyId), {
		enabled: !!tourneyId
	});
};

export const useGetTourneySidebarDetails = (tourneyId: string, userId?: string) => {
	return useQuery(['tourneySidebarDetails', tourneyId, userId || ''], async () => getTourneySidebarDetails(tourneyId), {
		refetchOnMount: false
	});
};

export const useGetModuleManageDetails = (moduleId: string, moduleFor: string, userId?: string) => {
	return useQuery(['moduleManageDetails', moduleId, moduleFor, userId || ''], async () => getUserManageDetails(moduleId, moduleFor), {
		enabled: !!userId && !!moduleId,
		refetchOnMount: false
	});
};

export const useGetTourneyOverviewDetails = (tourneyId: string, userId?: string) => {
	return useQuery(['tourneyOverviewDetails', tourneyId, userId || ''], async () => getTourneyOverviewDetails(tourneyId), {
		refetchOnMount: false
	});
};

export const useGetTourneyHeader = (tourneyId: string, userId?: string) => {
	return useQuery(['tourneyHeader', tourneyId, userId || ''], async () => getTourneyHeader(tourneyId), {
		refetchOnMount: false,
		enabled: !!tourneyId
	});
};

export const useGetTourneyEvents = (tourneyId: string, activityId: string = '', playerName: string = '') => {
	return useQuery(['tourneyEvents', tourneyId, activityId, playerName], async () => getTourneyEvents(`${tourneyId}`, activityId, playerName), {
		refetchOnMount: false,
		staleTime: 0.5 * 60 * 1000
	});
};

export const useGetTeamsInEvent = (eventId: string, activitySplitId?: string, enabled: boolean = false, calculateAgePerDate: string = '') => {
	return useQuery(
		['teamsInEvent', eventId, activitySplitId, calculateAgePerDate],
		async () => getTeamsInEvent(eventId, activitySplitId, calculateAgePerDate),
		{
			refetchOnMount: false,
			enabled
		}
	);
};

export const useGetPlayersWithEvents = (tourneyId: string, searchWord: string = '', calculateAgePerDate: string = '') => {
	return useQuery(['playersWithEvents', tourneyId, searchWord], async () => getPlayersWithEvents(`${tourneyId}`, searchWord, calculateAgePerDate), {
		refetchOnMount: false,
		staleTime: 0
	});
};

export const useGetTeamsInQueue = (tourneyId: string) => {
	return useQuery(['teamsInQueue', tourneyId], async () => getTeamsInQueue(tourneyId), {
		refetchOnMount: false
	});
};

export const useGetGamesOnCourt = (tourneyId: string) => {
	return useQuery(['gamesOnCourt', tourneyId], async () => getGamesOnCourt(tourneyId), {
		refetchOnMount: false
	});
};

export const useGetGoverningBodies = (tourneyId: string) => {
	return useQuery(['tourneyGoverningBodies', tourneyId], async () => getGoverningBodies(tourneyId), {
		refetchOnMount: false,
		enabled: !!tourneyId
	});
};
