import { useRouter } from 'next/router';
import { GoverningBody } from 'protos/model';
import React, { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';

import { MobileTourneyFilters } from './MobileTourneyFilters';
import { TourneyFilters } from './TourneyFilters';

export interface FilterState {
	keyword: string;
	featured: boolean;
	distance: number[];
	latitude: number | null;
	longitude: number | null;
	place: google.maps.places.PlaceResult | null;
	upcomingEvents: boolean;
	pastEvents: boolean;
	prizeMoney: boolean;
	partners: GoverningBody[];
}

export const initialFilters: FilterState = {
	keyword: '',
	featured: false,
	distance: [100, 600],
	latitude: null,
	longitude: null,
	place: null,
	upcomingEvents: true,
	pastEvents: false,
	prizeMoney: false,
	partners: []
};

interface FiltersProps {
	onSubmit: (filters: FilterState) => void;
	initialKeyword?: string;
	initialFeatured?: boolean;
}

export const Filters = ({ onSubmit, initialKeyword, initialFeatured }: FiltersProps) => {
	initialFilters.keyword = initialKeyword || '';
	initialFilters.featured = initialFeatured || false;

	const [filters, setFilters] = useState<FilterState>(initialFilters);
	const router = useRouter();
	const { pathname, query } = router;

	const updateFilter = <T extends FilterState, K extends keyof T>(key: K, value: T[K]) => {
		if (key === 'upcomingEvents') filters.pastEvents = false;
		if (key === 'pastEvents') filters.upcomingEvents = false;
		if (key === 'place') filters.distance = [100, 200];

		setFilters({ ...filters, [key]: value });
	};

	const submitFilters = React.useCallback(
		(internalFilters?: FilterState) => {
			onSubmit(internalFilters ? internalFilters : filters);
		},
		[filters, onSubmit]
	);

	const resetFilters = () => {
		// Race condition fix
		initialFilters.distance = [100, 600];
		initialFilters.place = null;
		initialFilters.latitude = null;
		initialFilters.longitude = null;
		initialFilters.keyword = '';
		initialFilters.featured = false;

		if (Object.keys(query).length > 0) router.push(pathname);

		setFilters(initialFilters);
		onSubmit(initialFilters);
	};

	useEffect(() => {
		submitFilters();
	}, [filters.keyword]);
	return (
		<>
			<div className="flex flex-col gap-4 lg:hidden">
				<div className="px-4">
					<DebounceInput
						type="text"
						className="h-12 w-full rounded-md border border-gray-300 bg-white px-3 py-1.5 text-sm focus:border-gray-200 focus:outline-none focus:ring focus:ring-gray-100"
						placeholder="Search for Tournaments..."
						minLength={2}
						debounceTimeout={750}
						value={filters.keyword}
						onChange={(e) => {
							updateFilter('keyword', e.target.value);
						}}
					/>
				</div>
				<MobileTourneyFilters
					filters={filters}
					updateFilter={(key, value) => {
						updateFilter(key, value);
					}}
					resetFilters={resetFilters}
					onSubmit={submitFilters}
				/>
			</div>
			<div className="hidden lg:block">
				<TourneyFilters
					filters={filters}
					updateFilter={(key, value) => {
						updateFilter(key, value);
					}}
					resetFilters={resetFilters}
					onSubmit={submitFilters}
				/>
			</div>
		</>
	);
};

export const geocodeLatLng = async (onSuccessFetch: (result: google.maps.GeocoderResult | undefined, lat: number, lng: number) => void) => {
	navigator.geolocation.getCurrentPosition(({ coords }) => {
		const geocoder = new window.google.maps.Geocoder();
		const latLng = new window.google.maps.LatLng(coords.latitude, coords.longitude);

		geocoder.geocode({ location: latLng.toJSON() }, (results, status) => {
			if (results && status === 'OK' && results[0]) {
				const result = results.filter((result) => result.geometry.location_type === 'APPROXIMATE' && ~result.types.indexOf('locality'))[0];
				/* setFilters({
            ...filters,
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            place: {
              formatted_address: results.filter(
                (result) =>
                  result.geometry.location_type === "APPROXIMATE" &&
                  ~result.types.indexOf("locality")
              )[0].formatted_address,
            },
          }); */
				onSuccessFetch(result, coords.latitude, coords.longitude);
			}
		});
	});
};
