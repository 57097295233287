import { faLoader, faLocation } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Range } from 'react-range';

import { Autocomplete } from './Autocomplete';
import { geocodeLatLng } from './Filters';

export const MINIMUM_DISTANCE = 100;
export const MAXIMUM_DISTANCE = 600;

interface LocationProps {
	place: google.maps.places.PlaceResult | null;
	onPlaceChange: (place: google.maps.places.PlaceResult | null) => void;
	distance: number[];
	onDistanceChange?: (distance: number) => void;
	hideDistance?: boolean;
}

export const Location = ({ place, onPlaceChange, distance, onDistanceChange, hideDistance }: LocationProps) => {
	const [fetchingLocation, setFetchingLocation] = React.useState(false);
	const [autoCompleteText, setAutoCompleteText] = React.useState<google.maps.places.PlaceResult | null>(place);

	return (
		<div className="flex w-full flex-col">
			<div className="relative mb-2">
				<Autocomplete
					place={autoCompleteText}
					isFetchingLocation={fetchingLocation}
					onPlaceChange={(place) => {
						if (place && place.geometry?.location) {
							onPlaceChange(place);
						}
					}}
				/>
				{fetchingLocation && (
					<div className="absolute inset-y-0 right-2 flex items-center">
						<FontAwesomeIcon icon={faLoader} spin />
					</div>
				)}
			</div>
			<div className="flex">
				<div
					className="mb-4 inline-flex cursor-pointer items-center text-sm hover:underline"
					onClick={() => {
						setFetchingLocation(true);
						geocodeLatLng((result) => {
							onPlaceChange(result);
							setAutoCompleteText(result);
							setFetchingLocation(false);
						});
					}}
				>
					<FontAwesomeIcon icon={faLocation} className="mr-1" />
					Use my current location
				</div>
			</div>

			{!hideDistance && (
				<div className="relative p-4 pt-10">
					<div className="absolute inset-x-4 bottom-4 flex justify-evenly">
						<div className="h-4 w-px bg-gray-200" />
						<div className="h-4 w-px bg-gray-200" />
						<div className="h-4 w-px bg-gray-200" />
						<div className="h-4 w-px bg-gray-200" />
					</div>

					<Range
						step={100}
						min={MINIMUM_DISTANCE}
						max={MAXIMUM_DISTANCE}
						allowOverlap={false}
						values={distance}
						onChange={(values) => {
							onDistanceChange && onDistanceChange(values[1]);
						}}
						renderTrack={({ props, children }) => (
							<div {...props} className="h-1 bg-blue-500">
								{children}
							</div>
						)}
						renderThumb={({ props, value, isDragged }) => {
							return (
								<div className="focus:outline-none" {...props}>
									<span className="absolute bottom-full left-1/2 mb-1 -translate-x-1/2 rounded-sm bg-gray-100 px-1 py-0.5 text-xs text-gray-700">
										{`${value === MAXIMUM_DISTANCE ? 'All' : value + 'mi'}`}
									</span>
									<div
										className={`size-5 rounded-full border focus:outline-none
                  ${props.key === 1 ? 'shadow-md' : ''}
                  ${props.key === 0 ? 'cursor-default bg-gray-100' : isDragged ? 'cursor-grabbing' : 'cursor-pointer'}
                  ${isDragged && props.key === 1 ? 'border-gray-200 bg-gray-200' : 'border-gray-100 bg-white'}`}
									/>
								</div>
							);
						}}
					/>
				</div>
			)}
		</div>
	);
};
