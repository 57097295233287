import { faArrowLeft, faArrowRight, faLoader, faMoneyBill } from '@fortawesome/pro-light-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RouteResponse } from '@pickleballinc/lib/server/next/route/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import moment from 'moment';
import * as React from 'react';

import { EventCard } from '@/components/EventCard';
import { GetTournamentsRes } from '@/pages/api/tourneys/getTournaments';
import { TournamentSearchData } from '@/types/tournaments.types';
import getCDNURL from '@/utils/getCDNURL';
import getPBracketsCDNURL from '@/utils/getPBracketsCDNURL';

import { renderTourneyCardLabels } from '../TourneysList';

let containerRef: React.RefObject<HTMLDivElement>;

const prevScroll = () => {
	if (containerRef.current) {
		containerRef.current.scroll({
			left: containerRef.current.scrollLeft - 300,
			behavior: 'smooth'
		});
	}
};

const nextScroll = () => {
	if (containerRef.current) {
		containerRef.current.scroll({
			left: containerRef.current.scrollLeft + 300,
			behavior: 'smooth'
		});
	}
};

export const PPATourneysCarousel = ({
	onEventClick,
	isHomepage
}: {
	isHomepage?: boolean;
	onEventClick: (tourney: TournamentSearchData) => void;
}) => {
	const page = 0;
	containerRef = React.useRef<HTMLDivElement>(null);
	const queryString = `?current_page=${page}&page_size=20&club_id=80f17003-cd92-4fc4-84e4-06d38e096042`;
	const { data, isLoading, isFetching } = useQuery(
		['ppa_tourneys', page],
		async () => {
			const response = await axios.get<RouteResponse<GetTournamentsRes>>(`/api/tourneys/getTournaments`, {
				params: { queryString }
			});
			return response.data.data;
		},
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			initialData: {
				result: [],
				totalRecords: 0
			}
		}
	);

	return (
		<>
			{' '}
			{data.result.length ? (
				<div
					className="bg-blue-900 py-10"
					style={{
						backgroundImage: `url(${getCDNURL(`${process.env.NEXT_PUBLIC_IMAGE_CDN}/pickleball-app/static/ppa-bg.svg`, 1000, 1000)})`,
						backgroundPosition: 'center bottom 6rem',
						backgroundRepeat: 'no-repeat',
						backgroundSize: 'cover'
					}}
				>
					<div className={`container mx-auto ${isHomepage && 'max-w-screen-2xl'}`}>
						<div className="flex flex-wrap items-center justify-between">
							<h1 className="text-2xl font-bold text-white" tabIndex={0}>
								PPA Tour
							</h1>
							<div className="flex">
								<button
									type="button"
									className="prev-scroll z-10 flex size-10 cursor-pointer items-center justify-center rounded-full border border-blue-500 transition duration-150 hover:bg-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-gray-100 lg:-left-6"
									onClick={prevScroll}
								>
									<FontAwesomeIcon icon={faArrowLeft} className="block text-base text-white" />
								</button>
								<button
									type="button"
									className="prev-scroll z-10 ml-4 flex size-10 cursor-pointer items-center justify-center rounded-full border border-blue-500 transition duration-150 hover:bg-blue-500 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-300 disabled:hover:bg-gray-100 lg:-right-6"
									onClick={nextScroll}
								>
									<FontAwesomeIcon icon={faArrowRight} className="block text-base text-white" />
								</button>
							</div>
						</div>

						<div className="container mx-auto mb-6 mt-4 px-0"></div>
						{isLoading || isFetching ? (
							<div className="flex flex-col items-center py-6 text-white">
								<FontAwesomeIcon icon={faLoader} spin className="mb-2 text-2xl" />
								<div>Fetching PPA tournaments...</div>
							</div>
						) : (
							<div className="relative">
								<div className="hidden-scrollbar -mx-2 overflow-x-auto" ref={containerRef}>
									<div className="inline-block p-2">
										<div className="flex">
											{data.result.map((tourney, index) => {
												return (
													<div
														key={tourney.TournamentID}
														className={`${index > 0 ? 'ml-6' : ''} flex w-[180px] select-none`}
														onClick={() => onEventClick(tourney)}
														tabIndex={0}
													>
														<EventCard className="flex w-full cursor-pointer flex-col rounded-md border border-blue-700 bg-blue-900">
															<div className="relative">
																{tourney.IsFeatured && (
																	<div className="">
																		<FontAwesomeIcon
																			icon={faStar}
																			className="absolute left-1 top-1/2 -mt-[7px] text-sm text-white"
																			data-tooltip-id="tooltip"
																			data-tooltip-content="Featured tournament."
																		/>
																	</div>
																)}
																{!isHomepage &&
																	renderTourneyCardLabels(tourney).map((item, index) => (
																		<React.Fragment key={index}>{item}</React.Fragment>
																	))}
															</div>
															<div
																className={`pointer-events-none relative bg-white ${isHomepage ? 'rounded-t-md' : ''}`}
															>
																<EventCard.Image
																	imageSrc={getPBracketsCDNURL(tourney.Logo, 178, 112)}
																	className="h-28"
																/>
																{tourney.IsPrizeMoney && (
																	<div className="absolute -bottom-1 right-2 flex size-6 items-center justify-center rounded-full bg-success-500">
																		<FontAwesomeIcon icon={faMoneyBill} className="text-[12px] text-white" />
																	</div>
																)}
															</div>
															<EventCard.Content>
																<p className="mb-1 text-xs text-blue-200">
																	{`${moment(tourney.TourneyFromDate).format('MMM DD, YYYY')} - ${moment(
																		tourney.TourneyToDate
																	).format('MMM DD, YYYY')}`}
																</p>
																<p
																	className={`line-clamp-2 whitespace-pre-wrap text-sm font-bold leading-4 text-white`}
																>
																	{tourney.Title}
																</p>
																<p className="mt-auto flex items-center pt-2 font-body text-xs text-white">
																	{`${tourney.LocationCity}, ${tourney.LocationState} ${tourney.LocationCountry}`}
																</p>
															</EventCard.Content>
															<EventCard.Footer borderColorClass="border-white">
																<div className="my-0">
																	<span className="text-xs text-white">{`${tourney.RegistrationCount} Players`}</span>
																</div>
															</EventCard.Footer>
														</EventCard>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			) : null}
		</>
	);
};
